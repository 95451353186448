import { h, render, Component } from 'preact';
import clipboard from "clipboard";
import { $ } from "./src/QuerySelector";
import "./src/styles/styles.css";
import logoUrl from './public/images/logo.svg';
const fancyTimeFormat = (time, format = 'HH:MM:SS') => {
  /* eslint-disable no-bitwise */

  // Hours, minutes and seconds
  const hrs = ~~(time / 3600) || 0;
  const mins = ~~((time % 3600) / 60) || 0;
  const secs = time % 60 || 0;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = format;

  if (hrs > 0) {
    ret = ret.replace('HH', `${hrs > 9 ? hrs : '0' + hrs.toString()}`);
    ret = ret.replace('H', `${hrs}`);
  } else {
    ret = ret.replace('HH:', '');
    ret = ret.replace('H:', '');
    ret = ret.replace('HH', '0');
    ret = ret.replace('H', '0');
  }

  ret = ret.replace('MM', `${mins > 9 ? mins : '0' + mins.toString()}`);
  ret = ret.replace('M', `${mins}`);

  ret = ret.replace('SS', `${secs > 9 ? secs : '0' + secs.toString()}`);
  ret = ret.replace('S', `${secs}`);

  return ret;
}

const farsiDigits = txt => {
  let newText = txt;
  for (let i = 0; i < 10; i++)
    newText = (newText + '').replace(
      new RegExp(i + '', 'g'),
      String.fromCharCode((i + '').charCodeAt(0) + (0x06f0 - 0x0030))
    );
  return newText;
}
const channel = JSON.parse($("#data").innerHTML);
const track = channel.tracks[0];
const url = 'https://shuffle.one/play'+ location.search;
const text = `${track.name} از ${(track.artists || []).map(
  (artist, index) =>
    `${artist.replace(/(.*)_/, '')}${
    index !== track.artists.length - 1 ? '، ' : ''
    }`
)} #در_حال_پخش #NowPlaying`;

console.log(clipboard)
class EmbedPlayer extends Component {
  constructor() {
    super();
    this.state = {
      playing: false,
      channel: channel,
      activeTrack: 0,
      track: track,
      loading: true
    }
    this.skipTrack = this.skipTrack.bind(this)
    this.initAudio = this.initAudio.bind(this)
    this.playPause = this.playPause.bind(this)
  }
  skipTrack(index) {
    this.setState({
      activeTrack: this.state.activeTrack + index,
      track: channel.tracks[this.state.activeTrack + index]
    })
  }
  componentWillMount() {
    this.initAudio();
  }
  initAudio() {
    this.audio = new Audio(track.oggPath);
    this.audio.autoplay = true;
    this.audio.oncanplay = () => {
      this.setState({ loading: false })
      setTimeout(() => {
        this.setState({ showDialog: true })
      }, 10000);
    };
    this.audio.onpause = () => this.setState({ playing: false });
    this.audio.onplay = () => {
      this.setState({ playing: true, ended: false })
    };
    this.audio.ontimeupdate = () => this.setState({ playing: true, currentTime: Math.floor(this.audio.currentTime) });
    this.audio.onended = () => this.setState({ ended: true, currentTime: 0, showDialog: true })
  }
  playPause() {
    if (!this.state.playing) return this.audio.play();
    return this.audio.pause();
  }
  render() {
    return (
      <div>

        <div
          className={`artwork pos-f w-100 h-100${this.state.playing ? ' playing' : ''}`}
          style={{
            backgroundImage: `url(${track.image})`
          }}
          onClick={this.playPause}
        >
          <div className="logo pos-a l-0 t-0">
            <a href={url} target="_blank" rel="noopener"><img src={logoUrl} alt="Shuffle" /></a>
          </div>
        </div>
        <div className="track-info pos-a w-100 b-0" dir="rtl">
          <div className="play" >
            <button onClick={this.playPause}>
              {this.state.loading && <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 40 40" enable-background="new 0 0 40 40"><path opacity="0.3" fill="currentColor" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"></path><path fill="currentColor" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z" transform="rotate(228.537 20 20)"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20" to="360 20 20" dur="0.8s" repeatCount="indefinite"></animateTransform></path></svg>}
              {!this.state.playing && !this.state.loading &&
                <svg fill="currentColor" viewBox="0 0 24 24" width="40" height="40">
                  <path d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                </svg>
              }
              {this.state.playing && !this.state.loading &&
                <svg fill="currentColor" viewBox="0 0 24 24" width="40" height="40">
                  <path d="M14,19H18V5H14M6,19H10V5H6V19Z" />
                </svg>
              }
            </button>
          </div>
          <div className="name">
            {track.name}
          </div>
          <div className="artists">
            {(track.artists || []).map(
              (artist, index) =>
                `${artist.replace(/(.*)_/, '')}${
                index !== track.artists.length - 1 ? '، ' : ''
                }`
            )}
          </div>
          {!this.state.ended &&
            <div className="progress-bar" dir="ltr">
              <time className="time pos-a d-flex">
                <span>{farsiDigits(fancyTimeFormat(Math.floor(this.state.currentTime)))}</span>
                <span>/</span>
                <span>{farsiDigits(fancyTimeFormat(Math.floor(track.durationMin * 60 + track.durationSec)))}</span>
              </time>
              <div className="progress" style={{ width: `${this.state.currentTime / this.audio.duration * 100}%` }}></div>
            </div>
          }
          <div className={`share pos-a${this.state.share ? ' active' : ''}`} dir="ltr">
            <button className="pos-a" onClick={() => this.setState({ share: !this.state.share })}>
              {!this.state.share &&
                <svg viewBox="0 0 24 24" width="30" height="30" fill="currentColor">
                  <path d="M20.5,11.5l-6.6-6.6v3.8C7.3,9.6,4.4,14.4,3.5,19.1c2.4-3.3,5.7-4.8,10.4-4.8v3.9L20.5,11.5z" />
                </svg>
              }
              {this.state.share &&
                <svg viewBox="0 0 24 24" width="30" height="30" fill="currentColor">
                  <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                </svg>
              }
            </button>
            <div className="share-options pos-a d-flex">
              <a
                href={`https://telegram.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`}
                target="_blank"
                data-tooltip="ارسال در تلگرام"
                rel="noreferrer noopener"
                style={{ color: "#2ca5e0" }}>
                <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                  <path d="M9.78,18.65L10.06,14.42L17.74,7.5C18.08,7.19 17.67,7.04 17.22,7.31L7.74,13.3L3.64,12C2.76,11.75 2.75,11.14 3.84,10.7L19.81,4.54C20.54,4.21 21.24,4.72 20.96,5.84L18.24,18.65C18.05,19.56 17.5,19.78 16.74,19.36L12.6,16.3L10.61,18.23C10.38,18.46 10.19,18.65 9.78,18.65Z" />
                </svg>
              </a>
              <a
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`}
                target="_blank"
                data-tooltip="اشتراک‌گذاری در توییتر"
                rel="noreferrer noopener"
                style={{ color: "#55acee" }}>
                <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                  <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                </svg>
              </a>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`}
                target="_blank"
                data-tooltip="اشتراک‌گذاری در فیس‌بوک"
                rel="noreferrer noopener"
                style={{ color: "#3b5998" }}>
                <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                  <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
                </svg>
              </a>
              <span className="cursor-pointer" style={{ color: '#555' }}
                onClick={e => {
                  e.stopPropagation();
                  var copyText = $("#text-to-copy");
                  copyText.select();
                  document.execCommand("copy");
                  this.setState({ linkCopied: true });
                  setTimeout(() => {
                    this.setState({
                      linkCopied: false
                    })
                  }, 1000);
                }}
                data-tooltip={this.state.linkCopied ? 'لینک کپی شد' : 'کپی کردن لینک'}
              >
                {!this.state.linkCopied &&
                  <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                    <path d="M16,6H13V7.9H16C18.26,7.9 20.1,9.73 20.1,12A4.1,4.1 0 0,1 16,16.1H13V18H16A6,6 0 0,0 22,12C22,8.68 19.31,6 16,6M3.9,12C3.9,9.73 5.74,7.9 8,7.9H11V6H8A6,6 0 0,0 2,12A6,6 0 0,0 8,18H11V16.1H8C5.74,16.1 3.9,14.26 3.9,12M8,13H16V11H8V13Z" />
                  </svg>
                }
                {this.state.linkCopied &&
                  <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                    <path d="M10,17L6,13L7.41,11.59L10,14.17L16.59,7.58L18,9M12,3A1,1 0 0,1 13,4A1,1 0 0,1 12,5A1,1 0 0,1 11,4A1,1 0 0,1 12,3M19,3H14.82C14.4,1.84 13.3,1 12,1C10.7,1 9.6,1.84 9.18,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3Z" />
                  </svg>
                }
              </span>
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(text)} ${encodeURIComponent(url)}`}
                target="_blank"
                data-tooltip="ارسال در واتس‌اپ"
                rel="noreferrer noopener"
                style={{ color: "#12af0a" }}>
                <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                  <path d="M16.75,13.96C17,14.09 17.16,14.16 17.21,14.26C17.27,14.37 17.25,14.87 17,15.44C16.8,16 15.76,16.54 15.3,16.56C14.84,16.58 14.83,16.92 12.34,15.83C9.85,14.74 8.35,12.08 8.23,11.91C8.11,11.74 7.27,10.53 7.31,9.3C7.36,8.08 8,7.5 8.26,7.26C8.5,7 8.77,6.97 8.94,7H9.41C9.56,7 9.77,6.94 9.96,7.45L10.65,9.32C10.71,9.45 10.75,9.6 10.66,9.76L10.39,10.17L10,10.59C9.88,10.71 9.74,10.84 9.88,11.09C10,11.35 10.5,12.18 11.2,12.87C12.11,13.75 12.91,14.04 13.15,14.17C13.39,14.31 13.54,14.29 13.69,14.13L14.5,13.19C14.69,12.94 14.85,13 15.08,13.08L16.75,13.96M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22C10.03,22 8.2,21.43 6.65,20.45L2,22L3.55,17.35C2.57,15.8 2,13.97 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12C4,13.72 4.54,15.31 5.46,16.61L4.5,19.5L7.39,18.54C8.69,19.46 10.28,20 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
                </svg>
              </a>
              <a
                href={`https://www.addtoany.com/share?linkurl=${encodeURIComponent(url)}&linkname=${encodeURIComponent(text)}`}
                rel="noopener noreferrer"
                target="_blank"
                data-tooltip="اشتراک گذاری در جاهای دیگه"
                style={{ color: '#0166ff' }}>
                <svg viewBox="0 0 24 24" width="25" height="25" fill="currentColor">
                  <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        {!this.state.linkCopied && <input type="text" id="text-to-copy" value={url} />}
      </div>
    )
  }
}

render(<EmbedPlayer />, document.getElementById('root'));